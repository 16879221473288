.tile {
    height: 60px;
    width: 60px;
    border-radius: 8px;
    border: 1px solid #cecece;
    font-size: 48px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.tile--highlighted {
        border-width: 1px;
        border-color: #52cbff;
        background-color: #e4f7ff;
    }

    &.tile--correct {
        border-width: 1px;
        border-color: #26ba24;
        background-color: #def5de;
    }

    &.tile--incorrect {
        border-width: 1px;
        border-color: #fa6969;
        background-color: #fee9e9;
    }
}

@media (min-width: 760px) {
    .tile {
        height: 72px;
        width: 72px;
    }
}

@media (min-width: 1280px) {
    .tile {
        height: 82px;
        width: 82px;
    }
}
