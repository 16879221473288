:root {
    --mobile-width: 342px;
    --tablet-width: 650px;
    --desktop-width: 852px;
    --footer-height: 155px;
    --footer-mobile-height: 80px;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.grid {
    display: grid;
    margin-bottom: calc(var(--footer-mobile-height) + 10px);
    gap: 8px;
    grid-template-columns: 62px 62px 62px 62px 62px;
}

@media (min-width: 760px) {
    .grid {
        gap: 22px;
        grid-template-columns: 74px 74px 74px 74px 74px 74px 74px;
        margin-bottom: calc(var(--footer-height) + 10px);
    }
}

@media (min-width: 1280px) {
    .grid {
        width: var(--desktop-width);
        margin-top: 0;
        grid-template-columns: 82px 82px 82px 82px 82px 82px 82px 82px;
        gap: 28px;
    }
}
